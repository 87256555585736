<template>
	<CKEditor :editor="template" :config="config" v-model="contentModel" />
</template>

<script>
	import CKEditor from "@ckeditor/ckeditor5-vue2";
	import Editor from "ckeditor5-custom-build/build/ckeditor";
	import CustomUploadAdapter from "@/assets/js/adapters/CKEditor5UploadAdapter";

	class CkParser {
		#ckStyles = null;

		constructor() {
			let ckStyles = [].concat(...[...document.styleSheets].map(style => [...style.cssRules].filter(rule => rule.selectorText?.includes(".ck-content")))).map(rule => {
				let styles = [];
				for (let i = 0; i < rule.styleMap.size; i++) styles.push(`${rule.style[i]}: ${rule.style[rule.style[i]]}`);
				return {
					rule: rule.selectorText.replaceAll(".ck-content", "").trim(),
					styles: `${styles.join("; ")};`
				};
			});
			let ckCss = [].concat(...[...document.styleSheets].map(style => [...style.cssRules].filter(rule => rule.selectorText?.includes(".ck-content")))).map(rule => rule.cssText).join("\n").replaceAll(".ck-content ", "");
			let ckVars = Object.assign({}, ...ckCss.match(/--([a-z-0-9,\s]+)/g).map(_var => ({ [_var]: getComputedStyle(document.documentElement).getPropertyValue(_var) })));

			Object.keys(ckVars).forEach(_var => {
				ckStyles.forEach(style => {
					style.styles = style.styles.replaceAll(`var(${_var})`, ckVars[_var]);
				});
			});

			this.#ckStyles = ckStyles;
		}

		parseIn(content) {
			let ckDoc = (new DOMParser()).parseFromString(content ?? "", "text/html");
			this.#ckStyles.forEach(style => {
				let elements = ckDoc.querySelectorAll(style.rule);
				if (!elements || elements.length == 0) return;
				[...elements].forEach(el => el.style.cssText = el.style.cssText?.replaceAll(style.styles, ""));
			});
			return ckDoc.body.innerHTML;
		}

		parseOut(content) {
			let ckDoc = (new DOMParser()).parseFromString(content ?? "", "text/html");
			this.#ckStyles.forEach(style => {
				let elements = ckDoc.querySelectorAll(style.rule);
				if (!elements || elements.length == 0) return;
				[...elements].forEach(el => el.style.cssText = `${style.styles} ${el.style.cssText?.trim()}`);
			});
			// Remo��o da margem criada pelo CKEditor para imagens
			ckDoc.querySelectorAll("figure.image").forEach(el => el.style.margin = "0 auto");
			// Remo��o da margem criada pelo CKEditor para <p>
			ckDoc.querySelectorAll("p").forEach(el => el.style.margin = "0");
			// Adicionar target _blank para as tags <a>
			ckDoc.querySelectorAll("a").forEach(el => el.target = "_blank");
			return ckDoc.body.innerHTML;
		}
	};

	export default {
		name: "CKEmailEditor",
		props: {
			content: ""
		},
		computed: {
			contentModel: {
				get() {
					return this.content;
				},
				set(value) {
					this.$emit("update:content", value);
				}
			},
			parser() {
				if (!this.ckParser) this.ckParser = new CkParser();
				return this.ckParser;
			}
		},
		components: {
			CKEditor: CKEditor.component
		},
		data() {
			return {
				template: Editor,
				config: {
					extraPlugins: [CustomUploadAdapter],
					image: {
						resizeUnit: "px"
					}
				}
			};
		},
		methods: {
			setContent(content) {
				this.contentModel = this.parser.parseIn(content);
			},
			getContent() {
				return this.parser.parseOut(this.content);
			}
		}
	}
</script>